import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import { compact, get } from 'lodash';
import { graphql, PageProps } from 'gatsby';

import { StrapiFooterCtaComponent } from '../../components/StrapiComponents/StrapiFooterCta';
import { StrapiEventCTAPanel } from '../../components/StrapiComponents/StrapiEventCTAPanel';
import ComponentRenderer from '../../components/ComponentRenderer/ComponentRenderer';
import LayoutWrapper, { intlWrapperHOC, v4tov3HOC } from '../../components/LayoutWrapper';
import Breadcrumb from '../../components/Breadcrumb';
import SchoolHero from '../../components/SchoolHero';
import SEO from '../../components/SEO/SEO';
import { Scalars, StrapiSingleSchoolPostPageQuery } from '../../../graphql.schema';
import { GlobalPageContext } from '../../types';
import { generateSchemaForSchools } from '../../lib/helpers';
import { v4tov3base } from '../../lib/mapV4toV3';

type PageContextType = GlobalPageContext & {
  id: Scalars['ID'];
};

const SchoolHomePage: React.FC<PageProps<StrapiSingleSchoolPostPageQuery, PageContextType>> = ({
  data,
  pageContext,
}) => {
  const { websiteLocale: websiteLocaleV3 } = pageContext;
  const websiteLocale = v4tov3base({ id: websiteLocaleV3.id, ...websiteLocaleV3.attributes });

  // hacking our way a bit here since publicationState seems to be an args on collections only in strapi v4
  // @ts-ignore
  const school = data.strapi.schools[0] as StrapiSingleSchoolPostPageQuery['strapi']['school'] | undefined;

  const intl = useIntl();

  if (!websiteLocale) {
    return null;
  }

  if (!school) {
    return (
      <LayoutWrapper {...pageContext}>
        <SEO
          title={school?.seo?.metaTitle ?? school.pageSettings?.title ?? school?.schoolHero?.heading ?? ''}
          description={school?.seo?.metaDescription ?? school?.pageSettings?.metaDescription}
          image={school?.seo?.metaImage?.url}
          avoidIndexing={school?.pageSettings?.avoidIndexing ?? false}
          canonicalURL={school?.seo?.canonicalURL}
          keywords={school?.seo?.keywords}
          metaSocial={school?.seo?.metaSocial}
          structuredData={school?.seo?.structuredData}
        />
        <h1>Not Found</h1>
      </LayoutWrapper>
    );
  }

  const [currentDate, setCurrentDate] = useState<string>('');

  useEffect(() => {
    setCurrentDate(new Date().toISOString());
  }, []);

  const activeSchoolEvents = compact(school.events).filter(
    (school) => currentDate && new Date(school?.eventTime).toISOString() > currentDate,
  );
  const [firstEvent, secondEvent] = activeSchoolEvents;
  const schoolInfoBox = compact(school?.components)?.find((comp) => comp.__typename === 'Strapi_ComponentPageSchoolInfoBox')
  return (
    <LayoutWrapper {...pageContext}>
      <SEO
        title={school.seo?.metaTitle ?? school.pageSettings?.title ?? school.schoolHero?.heading}
        description={school.seo?.metaDescription ?? school.pageSettings?.metaDescription}
        avoidIndexing={school.pageSettings?.avoidIndexing ?? false}
        image={school.seo?.metaImage?.url}
        canonicalURL={school.seo?.canonicalURL}
        keywords={school.seo?.keywords}
        metaSocial={school.seo?.metaSocial}
        structuredData={school.seo?.structuredData ?? generateSchemaForSchools(school, schoolInfoBox)}
      />
      <div className={classnames('container page-components')}>
        <Breadcrumb pageContext={pageContext} lastCrumb={school?.name}></Breadcrumb>
        <SchoolHero
          hero={school.schoolHero ?? undefined}
          address={school.address ?? undefined}
          mapLocation={school.mapMarker?.mapLocation ?? undefined}
          {...school}
          schooldata={school}
          websitelocale={websiteLocale}
        />
        {school.components?.map((component, idx) => {
          if (component?.__typename === 'Strapi_ComponentPageEventsCtaPanel') {
            const schoolEventProps = { ...component, linkedEvents: compact([firstEvent, secondEvent]) };
            return (
              <div className="container">
                <StrapiEventCTAPanel
                  slugBase={pageContext.pathPrefixes.events}
                  linkText={intl.formatMessage({ id: 'eventsCardText', defaultMessage: 'Learn more and RSVP' })}
                  {...schoolEventProps}
                  key={`school-homepage-component-${component.__typename}-${idx}`}
                />
              </div>
            );
          }
          return component ? (
            <ComponentRenderer
              component={component}
              key={`school-homepage-component-${component.__typename}-${idx}`}
              hasRTL={websiteLocale?.hasRightToLeftLanguage}
            />
          ) : null;
        })}
      </div>
      {get(websiteLocale, 'footerCta', null) && <StrapiFooterCtaComponent {...get(websiteLocale, 'footerCta', null)} />}
    </LayoutWrapper>
  );
};

export const query = graphql`
query StrapiSingleSchoolPostPage($id: ID!, $locale: Strapi_I18NLocaleCode) {
  strapi {
    schools(filters: {id: {eq: $id}}, locale: $locale) {
      data {
        id
        attributes {
          localizations {
            data {
              attributes {
                locale
              }
            }
          }
          ...School
        }
      }
    }
  }
}
`;

export default intlWrapperHOC(v4tov3HOC(SchoolHomePage));
