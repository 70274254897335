import React, { useContext } from 'react';
import classnames from 'classnames';

import StrapiButton from '../StrapiLink/StrapiButton';
import Map from '../Map';
import { RichTextField } from '../RichTextField/RichTextField';
import SpeechBubble from '../../components/SpeechBubble';
import { PageContext } from '../PageContext';
import {
  SchoolAddressFragment,
  SchoolHeroFragment,
  MapLocationFragment,
  StrapiSingleSchoolPostPageQuery,
  WebsiteLocaleFragment
} from '../../../graphql.schema';
import { ModalRawHTML } from '../RawHTML/ModalRawHTML';

import * as styles from './SchoolHero.module.scss';

export type SchoolHeroProps = {
  address?: SchoolAddressFragment;
  hero?: SchoolHeroFragment;
  name: string;
  mapLocation: MapLocationFragment;
  schooldata?: StrapiSingleSchoolPostPageQuery;
  websitelocale?: WebsiteLocaleFragment;
};

const SchoolHero = ({ address, hero, name, mapLocation, schooldata, websitelocale }: SchoolHeroProps ) => {
const { pathPrefixes } = useContext(PageContext);

  return (
    <div className={styles.heroSchool}>
      <div className={classnames('columns', styles.heroContainer)}>
        <div className="column is-6">
        {schooldata?.activePromotion?.id && (
          <SpeechBubble
            className={websitelocale?.hasRightToLeftLanguage ? styles.promotionRtl : styles.promotion}
            speechBubbleText={schooldata?.activePromotion?.title ?? ''}
            linkText={schooldata?.activePromotion?.homepage_link_text ?? ''}
            url={`${pathPrefixes.promotions}/${schooldata?.activePromotion.slug}`}
            isReverse={true}
          />
        )}
        <div>
          <h1 className="title is-1 brand-a mb-5">{hero?.heading}</h1>
          <RichTextField className="is-text-1 black-2 mb-4" content={hero?.description} />
          {hero?.callToAction ? (
            <StrapiButton className={classnames(styles.heroButton)} type="primary" {...hero.callToAction} />
          ) : null}
        </div>
        </div>
        <div className="column is-6">
          {hero?.showMap ? (
            <div className={classnames(styles.map)}>
              <Map
                markers={[
                  {
                    id: '1',
                    lat: mapLocation?.latitude || 0,
                    lng: mapLocation?.longitude || 0,
                    popUpHtml: mapLocation?.pop_up_html ? `<div>${mapLocation?.pop_up_html}</div>`: `<div><strong class="is-text-3">${name}</strong><p class="${classnames(
                      styles.mapAddress,
                      'is-text-3',
                    )}">${address?.streetAddress}</p></div>`,
                  },
                ]}
                width="100%"
                height="100%"
                defaultZoom={13}
                mapOptions={{
                  scrollwheel: false,
                  streetViewControl: false,
                  fullscreenControl: false,
                  mapTypeControl: false,
                  scaleControl: false,
                  panControl: false,
                  rotateControl: false,
                  clickableIcons: false,
                  styles: [
                    {
                      stylers: [{ visibility: 'simplified' }],
                    },
                  ],
                }}
              />
            </div>
          ) : (
            <ModalRawHTML html={hero?.replaceMapWith?.htmlCode} />
          )}
        </div>
      </div>
    </div>
  );
};

export default SchoolHero;
